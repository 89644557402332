<template>
    <div class="box">
        <p class="title is-5 has-text-centered">Marketing Preferences</p>
        <p class="mb-4">No longer want to receive marketing emails? No problem, please update your preferences below to unsubscribe.</p>
        <div class="box">
            <div class="mb-4 is-flex is-justify-content-center is-align-items-center">
                <label class="label mr-2 mb-0">Receive marketing emails:</label>
                <b-field>
                    <b-radio-button v-model="radioButton"
                                    native-value="yes"
                                    type="is-success">
                        <b-icon icon="check"></b-icon>
                        <span>Yes</span>
                    </b-radio-button>

                    <b-radio-button v-model="radioButton"
                                    native-value="no"
                                    type="is-danger">
                        <b-icon icon="close"></b-icon>
                        <span>No</span>
                    </b-radio-button>
                </b-field>
            </div>
            <button :disabled="buttonDisabled" class="button is-info is-fullwidth" @click="update">Update Marketing Preferences</button>
        </div>

        <b-notification v-model="updated" auto-close :duration=5000 aria-close-label="Close notification" type="is-success">
            Thank you for updating your marketing preferences.
        </b-notification>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    components: {},
    name: 'CustomerMarketingPreferences',
    created() {
        this.$http
            .post('/customer/is_subscribed', {
                email: this.customer.email,
            })
            .then((res) => {
                this.radioButton= res.data? "yes": "no";
            })
            .catch((err) => {
                console.log(err);
            });
    },
    data() {
        return {
            radioButton: '',
            updated: false,
            updating: false
        };
    },
    computed: {
        ...mapGetters({
            customer: 'customer',
        }),
        buttonDisabled: function (){
            return this.radioButton === '' || this.updating;
        }
    },
    methods: {
        update() {
            this.updating = true;
            this.$http
                .post('update_subscription', {  email: this.customer.email, optIn: this.radioButton==="yes" })
                .then(() => {
                    this.updated = true;
                    this.updating = false;
                })
                .catch((err) => console.log(err));
        },
    }
};
</script>

<style scoped></style>
